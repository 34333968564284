export default function Heart16() {
  return (
    <svg
      className="inline-block h-[36px] w-[30px]"
      viewBox="0 0 20 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-236, -448)" fillRule="nonzero">
          <g transform="translate(164.75, 426) rotate(-90) translate(-164.75, -426)translate(73.75, 334.75)">
            <g transform="translate(50, 162.5)">
              <g
                transform="translate(9.5, 10) rotate(90) translate(-9.5, -10)translate(-0.5, 0.5)"
                fill="#CF0808"
              >
                <path d="M18.3807706,1.65366637 C17.3337571,0.602483221 15.9029115,0.00704003316 14.4070653,0 L14.3556872,0 C12.6530948,-0.00427446098 11.0445388,0.767785521 9.99992133,2.09065844 C8.95548603,0.767933303 7.34720748,-0.00411576131 5.64484514,0 L5.62243184,0 C4.12327539,0.00305009829 2.68716651,0.593847128 1.63196542,1.64164522 C0.576764324,2.68944331 -0.0105042952,4.10782832 0,5.58286293 C0,10.8932666 9.37924539,18.5966089 9.77992619,18.921978 C9.90753506,19.0260073 10.0923076,19.0260073 10.2199165,18.921978 C10.6205973,18.5966089 19.9997004,10.8932666 19.9997004,5.58557717 C20.0151541,4.11484696 19.4322351,2.69910574 18.3807706,1.65366637 Z"></path>
              </g>
              <g
                transform="translate(9.987, 9.991) rotate(90) translate(-9.987, -9.991)translate(5.919, 5.968)"
                fill="#FFFFFF"
                stroke="#FFFFFF"
              >
                <path d="M2.772,0.486 L0.63,8.01 L0,8.01 L1.692,1.926 L0.738,3.186 L0.558,3.096 C0.858,2.736 1.182,2.31 1.53,1.818 C1.77,1.494 2.07,1.05 2.43,0.486 L2.772,0.486 Z"></path>
                <path d="M8.136,4.716 C8.136,5.424 7.86,6.126 7.308,6.822 C6.66,7.638 5.88,8.046 4.968,8.046 C4.428,8.046 3.966,7.836 3.582,7.416 C3.198,6.996 3.006,6.516 3.006,5.976 C3.006,5.34 3.228,4.638 3.672,3.87 C4.032,3.246 4.56,2.562 5.256,1.818 C5.748,1.29 6.378,0.684 7.146,0 L7.254,0.126 C6.642,0.774 6.144,1.338 5.76,1.818 C5.172,2.562 4.71,3.276 4.374,3.96 C3.966,4.8 3.762,5.586 3.762,6.318 C3.762,6.774 3.855,7.146 4.041,7.434 C4.227,7.722 4.494,7.866 4.842,7.866 C5.49,7.866 6.102,7.482 6.678,6.714 C7.206,6.006 7.47,5.322 7.47,4.662 C7.47,4.386 7.374,4.134 7.182,3.906 C6.99,3.678 6.756,3.564 6.48,3.564 C6.12,3.564 5.754,3.732 5.382,4.068 C5.094,4.332 4.83,4.662 4.59,5.058 L4.482,4.986 C4.734,4.506 5.046,4.104 5.418,3.78 C5.85,3.408 6.282,3.222 6.714,3.222 C7.134,3.222 7.476,3.366 7.74,3.654 C8.004,3.942 8.136,4.296 8.136,4.716 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
