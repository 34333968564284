import en from 'assets/i18n/en.json';
import pt from 'assets/i18n/pt.json';
import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

const DEFAULT_NAMESPACE = 'pt.martaeluis';

export async function initTranslations(): Promise<void> {
  if (!i18n.isInitialized) {
    await use(initReactI18next).init({
      /**
       * i18next options
       */
      fallbackLng: 'pt',
      ns: DEFAULT_NAMESPACE,
      resources: {
        pt: {
          [DEFAULT_NAMESPACE]: pt
        },
        en: {
          [DEFAULT_NAMESPACE]: en
        }
      },
      keySeparator: false,
      nsSeparator: false,
      /**
       * react-i18next options
       */
      react: {
        bindI18n: 'languageChanged'
      }
    });
  }
}
