export default function Rings(props: { className: string }) {
  return (
    <svg
      className={props.className}
      viewBox="0 0 18 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-477, -439)"
          stroke="#212121"
          strokeWidth="0.25"
        >
          <g transform="translate(325, 402)">
            <g transform="translate(25, 29)">
              <g transform="translate(128, 8.832)">
                <polygon points="4.81821369 3.18246452 7.47026746 1.06082151 6.40944595 0 3.21425158 0 2.16615993 1.06082151"></polygon>
                <path
                  d="M6.76057787,6.16443377 C5.91636616,8.22888884 6.60606396,10.602847 8.42504501,11.893574 C10.2440261,13.1843009 12.7125672,13.0513909 14.3824449,11.5728184 C16.0523227,10.094246 16.4831371,7.65995773 15.4221416,5.69808193 C14.3611461,3.73620614 12.0881589,2.76415187 9.93667746,3.35219596"
                  id="Path"
                ></path>
                <path
                  d="M9.24077855,9.74894964 C10.0860219,7.6841991 9.39689366,5.30927466 7.57773588,4.01764933 C5.75857809,2.726024 3.28923777,2.85839533 1.61858589,4.33709522 C-0.0520659808,5.81579511 -0.483354403,8.25078055 0.577776584,10.2133381 C1.63890757,12.1758956 3.9125589,13.148353 6.06467896,12.5601266"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
