import BomJesus from 'components/BomJesus';
import FadeInOnScroll from 'components/FadeInOnScroll';
import Glasses from 'components/Glasses';
import Heart16 from 'components/Heart16';
import MartaLuisWrittenLogo from 'components/MartaLuisWrittenLogo';
import Rings from 'components/Rings';
import ValeDaCorga from 'components/ValeDaCorga';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export function RootRoute() {
  const leftPaneRef = useRef<HTMLDivElement>(null);
  const rightPaneRef = useRef<HTMLDivElement>(null);

  const [renderContent, setRenderContent] = useState<boolean>(false);

  const open = () => {
    if (
      leftPaneRef.current &&
      rightPaneRef.current &&
      !leftPaneRef.current.classList.contains('-rotate-y-180') &&
      !rightPaneRef.current.classList.contains('rotate-y-180')
    ) {
      // Open left pane
      leftPaneRef.current.classList.add('-rotate-y-180');

      // Open right pane
      rightPaneRef.current.classList.add('rotate-y-180');

      // Show page content
      setTimeout(() => {
        setRenderContent(true);
      }, 300);
    }
  };

  return (
    <section>
      <div
        className="h-screen w-screen flex absolute inset-0 z-50 overflow-hidden"
        onClick={open}
      >
        <div
          ref={leftPaneRef}
          className="bg-cover bg-no-repeat bg-right w-1/2 h-full opening-img transition-transform duration-[2000ms] origin-left"
          style={{
            backgroundImage: "url('/bg_left.jpg')",
            animation: 'fadeIn 2s'
          }}
        ></div>
        <div
          ref={rightPaneRef}
          className="bg-cover bg-no-repeat bg-left w-1/2 h-full transition-transform duration-[1500ms] origin-right"
          style={{
            backgroundImage: "url('/bg_right.jpg')",
            animation: 'fadeIn 2s'
          }}
        ></div>
      </div>
      {renderContent && (
        <>
          <section className="text-center max-w-[600px] m-auto my-[64px] space-y-[64px] px-[32px]">
            <PageContent />
          </section>
          <PageFooter />
        </>
      )}
    </section>
  );
}

function PageContent() {
  const { t } = useTranslation();

  return (
    <>
      <FadeInOnScroll>
        <MartaLuisWrittenLogo />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <h2 className="text-center title-cornet text-[#CF0808]">
          {t('Junho de 2024')}
        </h2>
      </FadeInOnScroll>

      <table className="m-auto text-center max-w-[400px] text-cornet">
        <tbody>
          <FadeInOnScroll>
            <tr>
              <td className="w-[70px]">{t('seg')}</td>
              <td className="w-[70px]">{t('ter')}</td>
              <td className="w-[70px]">{t('qua')}</td>
              <td className="w-[70px]">{t('qui')}</td>
              <td className="w-[70px]">{t('sex')}</td>
              <td className="w-[70px]">{t('sab')}</td>
              <td className="w-[70px]">{t('dom')}</td>
            </tr>
          </FadeInOnScroll>
          <FadeInOnScroll>
            <tr>
              <td className="w-[70px]"></td>
              <td className="w-[70px]"></td>
              <td className="w-[70px]"></td>
              <td className="w-[70px]"></td>
              <td className="w-[70px]"></td>
              <td className="w-[70px]">1</td>
              <td className="w-[70px]">2</td>
            </tr>
          </FadeInOnScroll>
          <FadeInOnScroll>
            <tr>
              <td className="w-[70px]">3</td>
              <td className="w-[70px]">4</td>
              <td className="w-[70px]">5</td>
              <td className="w-[70px]">6</td>
              <td className="w-[70px]">7</td>
              <td className="w-[70px]">8</td>
              <td className="w-[70px]">9</td>
            </tr>
          </FadeInOnScroll>
          <FadeInOnScroll>
            <tr>
              <td className="w-[70px]">10</td>
              <td className="w-[70px]">11</td>
              <td className="w-[70px]">12</td>
              <td className="w-[70px]">13</td>
              <td className="w-[70px]">14</td>
              <td className="w-[70px]">15</td>
              <td className="w-[70px]">
                <Heart16 />
              </td>
            </tr>
          </FadeInOnScroll>
          <FadeInOnScroll>
            <tr>
              <td className="w-[70px]">17</td>
              <td className="w-[70px]">18</td>
              <td className="w-[70px]">19</td>
              <td className="w-[70px]">20</td>
              <td className="w-[70px]">21</td>
              <td className="w-[70px]">22</td>
              <td className="w-[70px]">23</td>
            </tr>
          </FadeInOnScroll>
          <FadeInOnScroll>
            <tr>
              <td className="w-[70px]">24</td>
              <td className="w-[70px]">25</td>
              <td className="w-[70px]">26</td>
              <td className="w-[70px]">27</td>
              <td className="w-[70px]">28</td>
              <td className="w-[70px]">29</td>
              <td className="w-[70px]">30</td>
            </tr>
          </FadeInOnScroll>
        </tbody>
      </table>

      <FadeInOnScroll>
        <p className="text-cornet">
          {t('Queremos convidar-vos para a festa da nossa vida!')}
        </p>
      </FadeInOnScroll>

      <FadeInOnScroll>
        <Rings className="w-[48px] inline-block" />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <p className="text-cornet">
          {t('A cerimónia realiza-se pelas 15h na')}
        </p>
      </FadeInOnScroll>

      <div className="flex flex-col items-center justify-center gap-[16px]">
        <FadeInOnScroll>
          <BomJesus className="w-[180px]" />
        </FadeInOnScroll>

        <FadeInOnScroll>
          <p className="text-cornet">{t('Igreja do Bom Jesus')}</p>
          <a
            className="text-uni whitespace-break-spaces"
            href="https://maps.app.goo.gl/VnpHs3KseGxhLdcy9"
          >
            {t('Estrada do Bom Jesus\n4715-056 Tenões')}
          </a>
        </FadeInOnScroll>
      </div>

      <FadeInOnScroll>
        <Glasses className="w-[48px] inline-block" />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <p className="text-cornet">{t('De seguida, brindamos juntos no')}</p>
      </FadeInOnScroll>

      <div className="flex flex-col items-center justify-center gap-[16px]">
        <FadeInOnScroll>
          <ValeDaCorga className="w-[210px]" />
        </FadeInOnScroll>

        <FadeInOnScroll>
          <p className="text-cornet">{t('Vale da Corga')}</p>
          <a
            className="text-uni whitespace-break-spaces"
            href="https://maps.app.goo.gl/MeB7Q435KLeMyAFw9"
          >
            {t('Rua Dona Goncinha 1200\n4785-107 Trofa')}
          </a>
        </FadeInOnScroll>
      </div>

      <div className="w-full h-1 border-t border-slate-200"></div>

      <FadeInOnScroll>
        <p className="text-cornet">{t('Confirmações até ao dia 16 de Maio')}</p>
      </FadeInOnScroll>

      <div className="flex flex-row flex-wrap gap-[64px] items-center justify-center">
        <FadeInOnScroll>
          <div className="text-cornet space-y-2">
            {t('Noiva:')}
            <a className="block text-uni" href="tel:+351931606400">
              +351 931 606 400
            </a>
            <a className="block text-uni" href="tel:+352661932801">
              +352 661 932 801
            </a>
          </div>
        </FadeInOnScroll>

        <FadeInOnScroll>
          <div className="text-cornet space-y-2">
            {t('Noivo:')}
            <a className="block text-uni" href="tel:+351931606409">
              +351 931 606 409
            </a>
            <a className="block text-uni" href="tel:+352661448398">
              +352 661 448 398
            </a>
          </div>
        </FadeInOnScroll>
      </div>
    </>
  );
}

function PageFooter() {
  return (
    <footer className="w-full py-[32px] bg-slate-50">
      <FadeInOnScroll>
        <div className="flex flex-row items-center justify-center gap-4">
          <NavLink to="/pt" reloadDocument className="text-uni">
            Português
          </NavLink>
          <NavLink to="/en" reloadDocument className="text-uni">
            English
          </NavLink>
        </div>
      </FadeInOnScroll>
    </footer>
  );
}
