import 'assets/styles/globals.scss';
import ReactDOM from 'react-dom/client';
import { initTranslations } from 'services/TranslationService';

import App from './App';

(async function startApplication() {
  await initTranslations();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(<App />);
})();
