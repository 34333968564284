import { ReactNode, useEffect, useRef, useState } from 'react';

import './FadeInOnScroll.scss';

export default function FadeInOnScroll(props: { children: ReactNode }) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const fadeInRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (fadeInRef.current) {
        const rect = fadeInRef.current.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

        if (isInView) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on component mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={fadeInRef} className={`fade-in ${isVisible ? 'active' : ''}`}>
      {props.children}
    </div>
  );
}
