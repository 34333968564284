import { EnglishPage } from 'pages/EnglishPage';
import { PortuguesePage } from 'pages/PortuguesePage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const routesConf = [
  {
    path: '/',
    element: <PortuguesePage />
  },
  {
    path: 'pt',
    element: <PortuguesePage />
  },
  {
    path: 'en',
    element: <EnglishPage />
  }
];

export function AppRouter() {
  const router = createBrowserRouter(routesConf);

  return <RouterProvider router={router}></RouterProvider>;
}
