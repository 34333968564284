import { changeLanguage } from 'i18next';
import { RootRoute } from 'pages';
import { useEffect } from 'react';

export function PortuguesePage() {
  useEffect(() => {
    changeLanguage('pt');
  }, []);

  return <RootRoute />;
}
