export default function Glasses(props: { className: string }) {
  return (
    <svg
      className={props.className}
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-460, -509)"
          stroke="#212121"
          strokeWidth="0.25"
        >
          <g transform="translate(328.5, 476)">
            <g transform="translate(39, 27)">
              <g transform="translate(92.7063, 6.7886)">
                <g transform="translate(3.6593, 7.7371) rotate(6) translate(-3.6593, -7.7371)translate(0.7656, 0.2615)">
                  <line
                    x1="2.89377625"
                    y1="9.64592082"
                    x2="2.89377625"
                    y2="14.4688812"
                    id="Path"
                  ></line>
                  <line
                    x1="0"
                    y1="14.4688812"
                    x2="5.78755249"
                    y2="14.4688812"
                    id="Path"
                  ></line>
                  <path
                    d="M0,-9.09494702e-14 L0,6.75214458 C0,8.35033305 1.29558777,9.64592078 2.89377625,9.64592078 C4.49196472,9.64592078 5.78755249,8.35033305 5.78755249,6.75214458 L5.78755249,-9.09494702e-14 L0,-9.09494702e-14 Z"
                    id="Path"
                  ></path>
                  <line
                    x1="1.8189894e-13"
                    y1="4.82296041"
                    x2="5.78755249"
                    y2="4.82296041"
                    id="Path"
                  ></line>
                </g>
                <g transform="translate(12.3407, 7.7371) rotate(-6) translate(-12.3407, -7.7371)translate(9.4469, 0.2615)">
                  <line
                    x1="2.89377625"
                    y1="9.64592082"
                    x2="2.89377625"
                    y2="14.4688812"
                    id="Path"
                  ></line>
                  <line
                    x1="1.8189894e-13"
                    y1="14.4688812"
                    x2="5.78755249"
                    y2="14.4688812"
                    id="Path"
                  ></line>
                  <path
                    d="M1.8189894e-13,0 L1.8189894e-13,6.75214458 C1.8189894e-13,8.35033304 1.29558778,9.64592076 2.89377625,9.64592076 C4.49196471,9.64592076 5.78755249,8.35033304 5.78755249,6.75214458 L5.78755249,0 L1.8189894e-13,0 Z"
                    id="Path"
                  ></path>
                  <line
                    x1="1.8189894e-13"
                    y1="4.82296041"
                    x2="5.78755249"
                    y2="4.82296041"
                    id="Path"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
